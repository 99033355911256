@import '../../theme/breakpoints.scss';

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  @media screen and (max-width: $max-tablet-width) {
    padding: 15px;
  }  
}
