@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 863px;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  gap: 29px;
  h1 {
    font-family: 'Comfortaa';
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    color: var(--blak-color)
  }
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.29px;
  text-align: left;
}
.toBuild {
  max-width: 560px;
  br {
    display: none;
  }
}

.instructionBlock {
  max-width: 654px;
  width: 100%;
  padding: 32px 39px;
  background-color: rgba(186, 170, 255, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 45px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;
  text-align: left;
  gap: 20px
}

$styles: (
  "0": 30px 30px 26px 30px,
  "1": 40px,
  "2": 18px 28px 0px 28px,
  "3": 20px,
  "4": 49px 0px 16px 0px,
);

@each $i, $style in $styles {
  .inner_#{$i} {
    max-width: 576px;
    width: 100%;
    padding: $style;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 500;
    overflow: hidden;

    > img {
      width: calc(100% - 78px);
    }
  }
}
  @media screen and (max-width: $min-tablet-width) {
    .wrapper {
      font-size: 10px;
      max-width: 346px;
      gap: 13px;
      h1 {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: -5px;
      }
    }
    .toBuild {
      line-height: 12.19px;
      margin-top: 3px;

      br {
        display: block;
      }

      & + div {
        margin-top: -5px;
        width: 245px;
      }
    }
    .instructionBlock {
      width: 100%;
      padding: 21px 16px;
      border-radius: 29px;
      font-size: 11px;
      line-height: 13.41px;
      gap: 8px;

      img {
        width: 100%;
      }
    }
    $styles: (
      "0": 15px 30px,
      "1": 22px 73px,
      "2": 4px 10px 0px,
      "3": 24px 12px 10px,
      "4": 24px 53px 17px,
    );
    @each $i, $style in $styles {
      .inner_#{$i} {
        padding: $style;
        border-radius: 21px;
      }
    }
}
