@import '../../App';

.container {
  width: 70%;
  height: 58px;
  border-radius: 62px;
  background-color: #D3C9FF;
  display: flex;
  align-items: center;
}
.innerContainer {
  height: 52px;
  border-radius: 62px;
  margin-left: 3px;
  background-color: white;
  width: 80%;
  display: flex;
  align-items: center;
}

.innerInput {
  width: 100%;
  padding: 0 10px;
  border: none;
  font-size: 16px;
  margin: 0 10px;
}

.innerInput::placeholder {
  font-size: 16px;
}

.innerInput:focus {
  outline: none;
}

.ok {
  font-size: 20px;
  font-weight: bold;
  width: 20%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: $min-tablet-width) {
  .container {
    height: 32px;
    border-radius: 36px;
  }

  .innerContainer {
    height: 26px;
    border-radius: 36px;
  }

  .innerInput {
    margin: 0 5px;
    padding: 0 10px;
    font-size: 10px;
  }

  .innerInput::placeholder {
    font-size: 10px;
  }

  .ok {
    font-size: 12px;
  }
}