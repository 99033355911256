@import '../../theme/breakpoints.scss';
@import '../../theme/colors.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  padding: 18px 80px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 45px;
}
.faq {
  cursor: pointer;
  font-family: 'Comfortaa';
  font-size: 15px;
  &:hover {
    text-decoration: underline;
    transition: all 0.5s;
  }

}
.polygon {
  transition: all 0.5s;
}
.languageButton {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  transition: all 0.5s;
  &:hover {
    text-decoration: underline;
    transition: all 0.5s;
    .polygon {
      transition: all 0.5s;
      transform: rotate(-90deg);
    }
  }
}
.languageDropdown {
  display: none;
}
.dropdown {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  left: -22px;
  top: 17px;
  width: 146px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 10000;
  gap: 14px;
  padding: 22px;
}
.menu {
  display: none;
}
@media screen and (max-width: $min-tablet-width) {
  .header {
    padding: 9px 16px;
  }
  .logo {
    width: 133px;
    height: 42px;
  }
  .buttonsContainer {
    gap: 20px;
    margin-right: 0px;
  }
  .dropdown {
    padding: 19px;

  }
}

@media screen and (max-width: 480px) {
  .buttonsContainer {
    display: none;
  }
  .menu {
    display: flex;
  }
  .menuWrapper {
    position: relative;
  }
}