@import '../../App.scss';

.wrapper {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 38px;
  button {
    margin-left: 0;
  }
  h1 {
    font-family: Comfortaa;
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    margin-bottom: 13px;
  }
  h2 {
    font-family: Montserrat;
    font-size: 27px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    margin-top: 21px;
    margin-bottom: 21px;
  }
  @media screen and (max-width: $max-tablet-width) {
    align-items: center;
    gap: 13px;
  }
}
.content {
  padding: 49px 87px 49px 35px;
  
}
.paragraph {
  padding: 36px 47px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}

@media screen and (max-width: $min-tablet-width) {
  .wrapper {
    h1 {
      font-size: 20px;
      line-height: 35px;
      margin-bottom: 0;
    }
    h2 {
      font-size: 12px;
      margin-top: 0;
      margin-bottom: 0;
    }
    button {
      margin-left: auto;
    }  
  }
  .content {
    padding: 23px 17px 23px 15px;
  }
  .paragraph {
    padding: 14px 18px;
    font-size: 8px;
    line-height: 12px;
  }
}
