@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.marketplaces {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 76px;
  gap: 19px;
}

.marketplacesText {
  font-family: 'Comfortaa';
  font-weight: 700;
  font-size: 40px;
}
.marketplacesBlocks {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 29px;
  justify-content: center;
  width: 100%;
  margin-bottom: 76px;
}

@media screen and (max-width: 1200px) {
  .marketplaces {
    flex-direction: column;
  }
}  

@media screen and (max-width: $max-tablet-width) {
  .marketplaces {
    margin-top: 26px;
  }
  .marketplacesBlocks {
    flex-direction: column;
    gap: 13px;
  }
  .marketplacesText {
    font-size: 24px;
  }
}  

