@import '../../theme/breakpoints.scss';
@import '../../App.scss';
@import '../../theme/colors.scss';

.block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 54px;
  text-align: center;
  position: relative;
}
.large {
  @extend .block;
  height: 308px;
  width: 490px;
}
.medium {
  @extend .block;
  height: 242px;
  width: 383px;
}
.text {
  font-family: 'Montserrat';
  z-index: 2;
  font-weight: 700;
  margin-bottom: 29px;
}
.text-large {
  @extend .text;
  font-size: 50px;
}
.text-medium {
  @extend .text;
  font-size: 40px;
}
.wb {
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 0;
}
.ozon {
  position: absolute;
  bottom: 0;
  left: 0;
}
.link {
  z-index: 1000;
}
.wb-medium {
  width: 293px
}
.wb-large {
  width: 421px
}
.ozon-medium {
  width: 195px
}
.ozon-large {
  width: 270px
}

@media screen and (max-width: $min-tablet-width) {
  .block {
    padding: 0 32px;
    height: 180px;
    width: 346px;
    border-radius: 35px;
    .wb-large, .wb-medium {
      width: 293px
    }
    .ozon-large, .ozon-medium {
      bottom: -10px;
      width: 195px
    }
    .text, .text-large, .text-medium {
      font-size: 32px;
      margin-bottom: 17px;
    }
    
  }
}
