@import '../../App.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  text-align: center;
  max-width: 832px;
  width: 100%;
  margin: 0 auto;
}
.contentWrapper {
    margin: 129px 66px 100px 66px;
    max-height: 520px;
    height: 100%;
}
.headline {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 34px;
  line-height: 35px;
  margin-bottom: 65px;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 33px;
  margin: 0 auto;
  margin-top: 6px;
  
  // @media screen and (max-width: $max-tablet-width) {
  //   width: 100%;
  //   justify-content: center;
  //   gap: 10px;
  // 
}
@media screen and (max-width: $min-tablet-width) {
  .headline {
    font-size: 18px;
    margin-bottom: 18px;
    line-height: 17px;
    background-image: none;
  }
  .contentWrapper {
    margin: 49px 26px 54px 26px;
  }
  .buttons {
    gap: 10px;
    flex-direction: column-reverse;
    align-items: center;
  }
}