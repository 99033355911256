@import '../../theme/breakpoints.scss';
@import '../../App.scss';
@import '../../theme/colors.scss';

.block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 53px 27px;
  align-items: center;
  height: 309px;
  width: 490px;
  border-radius: 54px;
  text-align: center;
  position: relative;
  button {
    align-self: flex-end;
  }
}
.text {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 37px;
  line-height: 35px;
}
.description {
  font-family: 'Comfortaa';
  font-weight: 700;
  font-size: 30px;
  color: var(--white-color);
}
@media screen and (max-width: $min-tablet-width) {
  .block {
    height: 192px;
    padding: 34px 26px;
    border-radius: 35px;
  }
  .text {
    font-size: 25px;
    line-height: 25px;
  }
  .description {
    font-size: 20px;
    line-height: 20px;
  }
}
