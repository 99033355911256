@import '../../theme/breakpoints.scss';

.notFoundPage {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9;
  .wrapperContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .container {
    max-width: 471px;
    text-align: center;
    margin: 0 auto;
  }
  .wrapperImg {
    margin: 67px 0;
    @media screen and (max-width: $max-tablet-width) {
      margin: 32px 0;
    }
    img {
      width: 100%;
      height: 60px;
      @media screen and (max-width: $phone-width) {
        height: 50px;
        object-fit: cover;
      }
    }
  }
  .title {
    font-family: 'Qanelas', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 180px;
    line-height: 120%;
    color: #D62472;
    @media screen and (max-width: $phone-width) {
      font-weight: 700;
      font-size: 120px;
      line-height: 120%;
    }
  }
  span {
    font-family: 'Qanelas', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    color: #020202;
    @media screen and (max-width: $phone-width) {
      font-size: 26px;
      line-height: 28px;
    }
  }
  .text {
    font-family: 'Qanelas';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #808080;
    margin-bottom: 34px;
    padding: 0 16px;
  }
}