@import '../../../App.scss';

.button {
  font-size: 50px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-align: center;
  user-select: none;
}

.canvasWrapper {
  overflow: hidden;
}

.eventWrapper {
  top: 0;
  left: 0;
  position: absolute;
  width: 740px;
  height: 960px;
  z-index: 10;
  touch-action: none;
}

.container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 740px;
  height: 960px;
}

.control {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 15;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.42);
  padding: 0 20px;
  gap: 20px;
  border-radius: 10px;
  margin: 4px;
}

@media screen and (max-width: $min-tablet-width) {
  .eventWrapper {
    width: 290px;
    height: 370px;
  }

  .container {
    width: 290px;
    height: 370px;
  }

  .button {
    font-size: 30px;
  }
}