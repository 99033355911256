@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.photoStyleContainer {
  text-align: center;
  margin-bottom: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-family: 'Comfortaa';
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    margin-bottom: 28px;
  }
}
.photosContainer {
  display: flex;
  flex-direction: row;
  gap: 23px;
  flex-wrap: wrap;
}
.buttonContainer {
  margin: 32px 20px;
  align-self: flex-start;
}
.styleName {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 192px;
  height: 76px;
  border-radius: 93px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 32px;
}
.surfaceWrapper {
  width: 363px;

  &.extended {
    width: 272px;

    img {
      max-width: 216px;
    }
  }

  img {
    max-width: 289px;
    max-height: 386px;
    border-radius: 21px;
    margin: 32px 38px;
  }
}

.purchaseContainer {
  margin-top: 48px;
  margin-bottom: 40px;
}

.marketplacesBlocks {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 29px;
  justify-content: center;
  }

  .moreOptions {
    padding: 44px 117px;
    font-family: 'Montserrat';
    font-size: 33px;
    font-weight: 500;
    line-height: 35px;
    text-align: center;
  }
@media screen and (max-width: 1140px) {
  .photosContainer {
    gap: 10px;
  }
  .surfaceWrapper {
    width: 300px;

    &.extended {
      width: 300px;

      img {
        max-width: 238px;
      }
    }

    img {
      max-width: 238px;
      max-height: 319px;
      margin: 26px 31px;
    }
  }
  }

  @media screen and (max-width: 950px) {
    .photosContainer{
    flex-wrap: wrap;
    justify-content: center;
      }
    }
@media screen and (max-width: 1050px) {
.marketplacesBlocks {
  flex-direction: column;
}
}

@media screen and (max-width: $max-tablet-width) {
  .moreOptions {
    padding: 30px 52px;
  }
  .styleName {
    width: 159px;
    height: 63px;
    border-radius: 93px;
    font-size: 16px;
  }
  .buttonContainer {
    margin: 20px 0;
    align-self: center;
  }
}

@media screen and (max-width: $min-tablet-width) {
  .moreOptions {
    padding: 20px 32px;
    font-size: 15px;
    line-height: 17px;
  }
.marketplacesBlocks {
  margin-top: 19px;
  flex-direction: column;
  gap: 13px;
}
.photoStyleContainer {

  h1 {
    font-size: 24px;
    line-height: 28px;
  }
}
}
