@import '../../App.scss';

.wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.square {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    cursor: pointer;
    width: 36px;
    height: 36px;
  }
}
.empty {
  @extend .square;
  background-color: #E0E0E0;
  border-radius: 4px;
}
.type {
  cursor: pointer;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  position: relative;
  p, span {
    position: absolute;
    color: white;
  }
  p {
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 600;
    left: 5px;
    bottom: 2px;
  }
  span {
    right: 2px;
    top: 3px;
    font-family: Comfortaa, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 16.73px;
  }
  @media screen and (max-width: $min-tablet-width) {
    width: 22px;
    height: 22px;
    p {
      font-size: 14px;
      line-height: 17px;
      left: 3px;
      bottom: 1px;
    }
    span {
      right: 3px;
      top: 2px;
      font-size: 9px;
      line-height: 10px;
    }
  }
}
.chosen {
  opacity: 0.2;
  pointer-events: none;
}
.colorButtonsWrapper {
  display: flex;
  flex-direction: row;
  width: 480px;
  justify-content: center;
}
.colorButtons {
  display: flex;
  justify-content: stretch;
  gap: 8px;
}
.headerRow {
  font-family: Comfortaa, sans-serif;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  text-align: center;
  color: var(--grey-color-400);
}
.headerColumn{
  @extend .headerRow;
  height: unset;
  width: 50px;
}
.allColors {
  border-radius: 4px;
  background-color: var(--purple-color);
  border: none;
  min-width: 184px;
  height: 36px;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.29px;
  margin-left: 30px;
  cursor: pointer;
}
.controls {
  display: flex;
  flex-direction: row;
  gap: 21px;
  > button {
    height: 44px;
    background-color: rgba(186, 170, 255, 0.1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 2px solid var(--dark-purple-color);
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
  }
}
.reset {
  width: 130px;
}
.cancel {
  width: 44px;
}

@media screen and (max-width: $min-tablet-width) {
    .square {
      >svg {
        width: 22px;
        height: 22px;  
      }
    }
    .empty {
      img {
        width: 22px;
        height: 22px;  
      }
    }
    .headerRow {
      font-size: 14px;
      line-height: 16px;
    }
    .colorButtonsWrapper {
      gap: 5px;
      width: 360px;
    }
    .colorButtons {
      gap: 4px;
    }
    .allColors {
      min-width: 133px;
      height: 23px;
      font-size: 11px;
      line-height: 13px;
      margin-left: 20px;
    }
    .allColors {
      margin-left: 12px;
    }
    .headerRow {
      height: 20px;
    }
    .headerColumn {
      width: 20px;
      height: unset;
    }
    .wrapper {
     width: 361px;
    }
    .controls {
      gap: 16px;
      > button {
        height: 34px;
        font-size: 13px;
      }
    }
    .reset {
      width: 100px;
    }
    .cancel {
      width: 34px;
      > svg {
        width: 16px;
      }
    }  
  
  }  
