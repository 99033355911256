@import '../../App.scss';

.title {
  text-align: center;
  margin-bottom: 45px;
  font-family: Comfortaa;
  font-size: 40px;
  font-weight: 700;
  line-height: 45px;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  > div > button {
    margin-top: 20px;
    margin-left: 0;
  }
  @media screen and (max-width: $max-tablet-width) {
    align-items: center;
    > div > button {
      margin-top: 10px;
      margin-left: auto;  
    }
  }

}
.description {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 45px;
  color: white;
}
.text {
  font-family: 'Comfortaa';
  font-weight: 700;
  font-size: 36px;
  color: black;
  margin-right: 15px;
}

@media screen and (max-width: 600px) {
  .text, .description {
    font-size: 29px;
    line-height: 25px;
    margin-right: 0px;
  }
  .title {
    display: none;
  }
}