@import '../../theme/breakpoints.scss';

.wrapper {
  padding: 24px 32px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  background-color: var(--purple-color-500);
  border-radius: 37px;
  height: 250px;

  p {
    text-align: center;
    font-size: 30px;
    line-height: 29px;
    font-weight: 500;
  }

  img {
    max-width: 208px;
    max-height: 208px;
    border-radius: 16px;
  }

  @media (max-width: $min-tablet-width) {
    gap: 12px;
    padding: 15px;
    border-radius: 22px;
    height: 150px;

    p {
      font-size: 16px;
      line-height: 17.3px;
    }

    img {
      max-width: 125px;
      max-height: 125px;
    }
  }
}
