@import './theme/colors.scss';
@import './theme/breakpoints.scss';
@import './theme/fonts.scss';

$boxShadow: 0px 4px 45px rgba(2, 2, 2, 0.2);

html,
body,
#root {
  overflow-x: hidden;
  font-weight: normal;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
  color: var(--black-color);
}

a {
  text-decoration: none;
  color: var(--black-color);
}

h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 120%;
}

h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
}

h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 28px;
}

.body1 {
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
}

.body2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.body3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.input {
  border: 0;
  width: 50px;
  height: 74px;
  display: inline-block;
  background-color: white;
  font-family: 'Montserrat';
  font-size: 32px;
  font-weight: 400;
  line-height: 28px;
  margin-right: 18px;
  border-radius: 12px;
  text-align: center;
  &:nth-child(3n) {
    margin-right: 31px;
  }

  &:focus-visible {
    border: 1px solid var(--purple-color);
    background-clip: padding-box;
    outline: none
  }
  &__error {
    border: 1px solid var(--error-color);
    background-clip: padding-box;
    outline: none
  }
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentCenter {
  @extend .flexCenter;
  flex-direction: column;
  text-align: center;
  padding: 40px 20%;
}

.photoContainer {
  margin: 32px 0 64px 0;
  @extend .flexCenter;
}

@mixin buttonMarketplace($bg-url) {
  background-image: url($bg-url);
  background-position: center;
  width: 406px;
  height: 66px;
  @media screen and (max-width: $max-tablet-width) {
    width: 358px;
  }
}

.black-btn {
  background-color: var(--black-color);
  color: var(--white-color);
  border: 0;
  &:hover {
    background: var(--grey-color-400);
  }
  &:disabled {
    background-color: var(--grey-color-200);
  }
}

.outline-btn {
  background-color: transparent;
  color: var(--black-color);
  border: 1px solid var(--black-color);
  &:hover {
    background-color: var(--light-blue);
  }
  &:disabled {
    color: var(--grey-color-200);
    border-color: var(--grey-color-200);
  }
}

.pink-btn {
  background-color: var(--pink-color);
  color: var(--white-color);
  border: 0;
  &:hover {
    background-color: rgba(237, 37, 124, 1);
  }
  &:disabled {
    background-color: var(--grey-color-200);
  }
  &:active {
    background-color: #470925;
  }
}

.wbButton {
  @include buttonMarketplace('./assets/wildberries.svg');
  margin-top: 32px;
  margin-bottom: 20px;
}

.ozonButton {
  @include buttonMarketplace('./assets/ozon.svg');
  margin-bottom: 40px;
}

@media screen and (max-width: $min-tablet-width) {
  h1 {
    font-size: 26px;
    line-height: 28px;
  }

  .body1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
  h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
  }
}

body.popupOpen {
  overflow: hidden;
  height: 100vh;
}

.buttonsNavigationWrapper {
  display: flex;
  align-items: center;
  gap: 32px;
  margin: 40px 0 70px;

  button {
    padding: 13px 70.5px;
  }
}

.emailError {
  color: var(--pink-color);
}

.buttonNo {
  margin-right: 32px;
}

.textCenter {
  text-align: center;
}

.swiper-scrollbar {
  top: 0 !important;
  margin-top: 8px !important;
  height: 16px !important;
  width: 100%;
  background-color: #F5F5F5 !important;
  @media screen and (max-width: $min-tablet-width) {
    height: 10px !important;
  }
}
.swiper-scrollbar-drag {
  width: 44px !important;
  background-color: var(--purple-color)  !important;
  cursor: pointer;
  @media screen and (max-width: $min-tablet-width) {
    width: 24px !important;
  }
}
@media screen and (max-width: $max-tablet-width) {
  .buttonsNavigationWrapper {
    gap: 16px;
    button {
      padding: 13px 62px;
    }
  }

  .photoContainer {
    flex-direction: column;
  }

  .input {
    margin-right: 8px;

    &:nth-child(3n) {
      margin-right: 15px;
    }
    width: 24px;
    height: 36px;
    font-size: 18px;
    border-radius: 5px;
  }
  .contentCenter {
    padding: 40px 10%;
  }
}