/* MAIN COLORS */
:root {
  --black-color: #131313;
  --grey-color-100: #eeeeee;
  --grey-color-200: #dddddd;
  --grey-color-300: #999999;
  --grey-color-400: #828282;
  --white-color: #ffffff;
  --pink-color: #d62472;
  --error-color: #ED145B;
  --light-blue-color: #dbf5ff;
  --blue-color: #00b5fc;
  --light-blue: #e4f8ff;
  --purple-color: #D3C9FF;
  --purple-color-500: #BAAAFF33;
  --dark-purple-color: #BAAAFF;
  --black-color-100: rgba(0, 0, 0, 0.5);
  --black-color-200: 4px 0 20px rgba(0, 0, 0, 0.25);
  --o-bg: #C9C9C9;
  --b-bg: #818384;
  --c-bg: #575A59;
  --d-bg: #3A3D41;
  --x-bg: #1A1A1A;
  --e-bg: #D32178;
  --t-bg: #039889;
  --l-bg: #E4CA46;
  --m-bg: #E39F14;
  --a-bg: #443584;
  --p-bg: #F4D5A1;
  --k-bg: #DB9C61;
  --w-bg: #A46536;
  --f-bg: #955327;
  --s-bg: #421F1F;
  --empty-bg: #E0E0E0;
  --a-em-bg: #C9C9C9;
  --b-em-bg: #000000;
  --c-em-bg: #EF7BA1;
  --d-em-bg: #A772A4;
  --e-em-bg: #FCE740;
  --f-em-bg: #FFE35F;
  --g-em-bg: #6C91C0;
}
