@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 37px;
    font-family: 'Comfortaa';
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    max-width: 618px;
    width: 100%;
    @media screen and (max-width: $min-tablet-width) {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 20px;
    }  
  }
}
