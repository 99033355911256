@import '../../theme/colors.scss';
@import '../../theme/breakpoints.scss';

.buttonWrapper_medium {
  display: flex;
  width: fit-content;
  min-width: 280px;
  z-index: 100;
  transition: all 0.5s ease;
  cursor: auto;
  &:hover {
    .primary, .primary-color {
      &.medium {
          width: 202px;
    }
    .arrow {
      transition: all 0.5s ease;
      opacity: 0;
    }
    }
    .large {
      width: 253px;
      }

  }
  &:disabled {
    cursor: not-allowed;
    pointer-events:none;
    opacity: 0.5;
  }

}
.buttonWrapper_large {
  @extend .buttonWrapper_medium;
  width: 356px;
  &:hover {
    width: 340px;
  }
}
.button {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
  cursor: pointer;
  color: #000000;
  border-radius: calc(85px / 2);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  position: relative;
  transition: all 0.5s;  
  .arrow { 
    transition: all 0.5s;  
    opacity: 1;
    margin: 0px 27px 0px 19px;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 37px;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events:none;
    opacity: 0.5;
  }
}

.medium {
  @extend .button;
  height: 85px;
  width: 279px;
  .arrow {
    width: 29px;
  }
  .inner {
    height: 75px;
    width: 190px;
    border-radius: calc(75px / 2);
  }
}
.large {
  @extend .button;
  height: 109px;
  width: 355px;
  font-size: 30px;
  border-radius: calc(109px / 2);
  .inner {
    height: 97px;
    width: 245px;
    border-radius: calc(97px / 2);
  }
  .arrow {
    width: 46px;
  }
}

.primary, .primary-color {
  .arrow {
    margin: 0;
    position: absolute;
    right: 25px
  }
} 

.pink {
  &:active {
    .inner {
      background: #FF8BB5 !important;
    }
  }
}
.yellow {
  &:active {
    .inner {
      background: #FFED46 !important;
    }
  }
}
.purple {
  &:active {
    .inner {
      background: #BAAAFF !important;
    }
  }
}
.blue, .ozon {
  &:active {
    .inner {
      background: #52C1FF !important;
    }
  }
}
.wb {
  &:active {
    .inner {
      background: #F53CC2 !important;
    }
  }
}
.primary {
  @extend .button;
  background-color: var(--black-color);
  border: solid var(--black-color) 5px;
  padding: 1px;
  .inner {
    background-color: var(--white-color);
  }
  &:active {
    border-color: #4F4F4F ;
    .inner {
      color: #4F4F4F;
    }
  }
}
.primary-color {
  .arrow {
    right: 27px;
  }
  @extend .button;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 0px 6px;
  &:disabled {
      .arrow {
        fill:#BDBDBD;
      }
  }
}
.secondary {
  @extend .button;
  border: solid var(--purple-color) 5px;
  background-color: var(--purple-color);
  width: fit-content;
  color: black;
  .inner {
    display: flex;
    flex-direction: column;
    min-width: 171px;
    width: fit-content;
    background-color: var(--white-color);
    .prizeDraw {
      font-weight: 700;
      font-size: 24px;
      line-height: 14px
    }
  }
  &:hover {
    background-color: #BAAAFF;
    border-color: #BAAAFF;

  }
  &:active {
    background-color: #9F89FF;
    border-color: #9F89FF;
  }
}
.download {
  @extend .secondary;
  .arrow {
    transform: rotate(90deg)
  }
}
.tertiary {
  @extend .button;
  padding: 16px 37px;
  min-width: 191px;
  width: fit-content;
  background-color: var(--white-color);
  align-items: center;
  justify-content: center;
  border: solid var(--purple-color) 5px;
  &:hover {
    border-color: #BAAAFF;
  }
  &:active {
    border-color: #9F89FF;
  }
}
.orange {
  transition: all 0.5s ease;
  &.secondary {
    &:hover {
      background-color: #FF7E1D !important;
    }
    &:active {
      background-color: #FF6E00 !important;
    }  
  }
  &:hover {
    border-color: #FF7E1D !important;
  }
  &:active {
    border-color: #FF6E00 !important;
  }
}

.secondary  {
  & .orange {
    &:hover {
      background-color: #FF7E1D !important;
    }
    &:active {
      background-color: #FF6E00 !important;
    }  
  }
}

@media screen and (max-width: $min-tablet-width) {
  .buttonWrapper_large, .buttonWrapper_medium {
    width: 198px;
    min-width: 100px;
    transition: all 0.4s ease;
    &:active, &:hover {
      width: 200px;
      .primary {
        &.medium, &.large {
          width: 186px;
        }
        background-color: #4F4F4F;
        border-color: #4F4F4F;
        color: #4F4F4F;
        .arrow {
          opacity: 1;
        }
      }
      .primary-color {
        &.medium, &.large {
          width: 198px;
        }
        .arrow {
          opacity: 1;
        }

      }
    }
  }
  .button {
    width: 198px;
    height: 60px;
    border-radius: calc(60px / 2);
    font-size: 16px;
    // padding: 5px;
    border-width: 3px;
    .arrow {
      right: 8px;
      width: 24px;
      margin: 0px 10px 0px 5px;
    }
    .inner {
      min-width: 120px;
      width: 136px;
      height: 54px;
      border-radius: calc(54px / 2);
      .prizeDraw {
        font-size: 16px;
        line-height: 12px
      }  
    }
  }
  .secondary {
    padding: 0px;
    width: fit-content;
    height: 50px;
    border-radius: 25px;
    font-size: 14px;
    .inner {
      width: fit-content;
      height: 44px;
      padding: 0 20px;
    }
  }
  .tertiary {
    width: fit-content;
    font-size: 14px;
    padding: 0 30px;
    min-width: 120px;
    height: 50px;
    border-radius: 25px;
  }
  .primary {
    width: 186px;
    height: 57px;
    border-radius: calc(57px / 2);
    padding: 0px;
    .inner {
      width: 128px;
      height: 51px;
      border-radius: calc(51px / 2);
    }
    .arrow {
      right: 5px;
    }
  }
  .primary-color {
    padding: 0px 3px;
  }
  .download {
    .arrow {
      transform: rotate(0deg)
    }
  }
  .orange {
    .inner {
      min-width: 190px;
      padding: 0 10px;
    }
  }
}
