@import '../../theme/breakpoints.scss';
@import '../../theme/colors.scss';

.header {
  font-family: 'Montserrat';
  font-size: 34px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
}
.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}
.percent {
  font-family: 'Montserrat';
  font-size: 32px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  width: 80px;
  margin-top: 10px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 750px;
  margin: 167px 40px 183px 40px;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 31px;
  }

  &.secondary {
    margin: 0;
    padding: 40px;
    height: 510px;
  }
}
.spinner {
  $spinnerTickCount: 8;
  $loopTime: 1;
  position: relative;
  width: 89px;
  height: 89px;
  display: inline-block;
  margin-right: 50px;
  > div {
    width: 8px;
    height: 24px;
    background: var(--dark-purple-color);
    position: absolute;
    left: 44.5%;
    top: 37%;
    opacity: 1;
    animation: fade #{$loopTime}s linear infinite;
    border-radius: 50px;
    @for $i from 1 through $spinnerTickCount {
      $rotation:   ($i - 1)*(360/$spinnerTickCount);
      $fadeTiming: -(($loopTime)-($i - 1)*($loopTime/$spinnerTickCount));
      &.bar#{$i} {
        transform: rotate(#{$rotation}deg) translate(0, -142%);
        animation-delay: #{$fadeTiming}s;
      }
    }
  }
}
@keyframes fade {
    from {opacity: 0.3;}
    to {opacity: 1;}
}


@media screen and (max-width: $min-tablet-width) {
  .percent {
    margin-top: 0px;
    font-size: 20px;
    margin-left: 0px;
  }
  .header {
    font-size: 16px;
    margin-top: 0px;
  }
  .percent {
    width: 50px
  }
  .wrapper {
    gap: 0;
    min-width: 290px;
    margin: 86px 32px;

    &.secondary {
      height: unset;
      gap: 8px;
    }
  }
  .spinner {
    width: 52px;
    height: 52px;
    margin-right: 5px;
    >div {
      width: 5px;
      height: 14px;
    }
  }
}
