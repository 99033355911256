@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.title {
  font-family: 'Comfortaa';
  font-size: 40px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
  margin-bottom: 50px;
}
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 39px auto;
  gap: 20px;
  position: relative;
  max-width: 1400px;
}
.buttons {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 30px;
}
.description {
  font-family: 'Montserrat';
  font-size: 21px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  margin: 20px 80px;
}
.photos {
  max-width: 608px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 25px;
  position: relative;
  .photo_element { 
    width: 291px;
    height: 388px;
    border-radius: 21px;
  }
  .icons {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: space-between;
    gap: 338px;
    left: 44%;
    top: 20%;
  }  
}

@media screen and (max-width: 1280px) {
  .wrapper {
    flex-direction: column;
  }
  .buttons {
    position:unset;
    width: 80%
  }
}

@media screen and (max-width: $min-tablet-width) {
  .photos {
    gap: 14px;
    max-width: 414px;
    .photo_element { 
      width: 200px;
      height: 253px;
    }  
    .icons {
      left: 45%;
      top: 20%;
      gap: 238px;
      .icon_element {
        width: 41px;
      }
    }
  }
  .buttons {
    width: 90%;
  }
  .title {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 20px;
  }
  .description {
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    margin: 20px 39px;
  }  
  .wrapper {
    margin-top: 26px;
  }
}  

@media screen and (max-width: 450px) {
  .photos {
    max-width: 334px;
    .photo_element { 
      width: 160px;
      height: 213px;
      border-radius: 21px;
    }  
    .icons {
      left: 44%;
      top: 20%;
      gap: 190px;
  }
}
}