@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.photoEditor {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__container {
    width: 555px;
    height: 555px;
    position: relative;
    @media screen and (max-width: $min-tablet-width) {
      width: 347px;
      height: 347px;
      }  
  }
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  max-width: 548px;
  width: 100%;
  margin-left: -20px;
  margin-top: 41px;
  justify-content: space-between;
  @media screen and (max-width: $min-tablet-width) {
    max-width: 299px;
    margin-top: 26px;
  }
}

@media screen and (max-width: $min-tablet-width) {
  h1 {
    line-height: 20px;
  }
}