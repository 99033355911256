@import '../../theme/colors.scss';
@import '../../App.scss';
@import '../../theme/breakpoints.scss';

.instructionConatainer {
  display: flex;
  flex-direction: column;
  max-width: 832px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  h1 {
    font-family: 'Montserrat';
    font-size: 37px;
    font-weight: 700;
    line-height: 35px;
    margin-top: 96px;
    margin-bottom: 64px;
    width: 95%;
  }
}
.errorContainer {
  @extend .instructionConatainer;
  input {
    border: 1px solid var(--error-color);
    background-clip: padding-box;
  }
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  max-width: 549px;
  width: 100%;
  margin-top: 49px;
  margin-left: -30px;
  margin-bottom: 75px;
  justify-content: space-between;
}
.errorText {
  font-family: 'Montserrat';
  font-size: 25px;
  font-weight: 400;
  color: var(--error-color)
}
@media screen and (max-width: $min-tablet-width) {
  .buttonsContainer {
    max-width: 299px;
    margin-top: 26px;
    margin-bottom: 44px;  
  }
  .instructionConatainer{
    h1 {
      font-size: 18px;
      line-height: 17px;
      margin-top: 43px;
      margin-bottom: 19px;  
    }
  }
}
