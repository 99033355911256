@import '../../theme/colors.scss';
@import '../../theme/breakpoints.scss';

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-top: 15px;
  gap: 20px;
  margin-right: 90px;
  @media screen and (max-width: $max-tablet-width) {
    justify-content: center;
  }

}
.emailWrapper {
  max-width: 832px;
  margin: 0 auto;
  width: 100%;

}
.emailContainer {
  margin: 69px 100px 77px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 30px;
  text-align: center;
}
.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 39px;
}
.enterEmail {
  color: var(--grey-color-300);
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 7px;
}

@media screen and (max-width: $min-tablet-width) {
  .text {
    font-size: 20px;
    margin-bottom: 11px;
    // max-width: 95%;
  }
  .enterEmail {
    font-size: 14px;
  }
  .emailContainer {
    margin: 35px 27px;
    width: 90%;
    svg {
      width: 53px;
    }
  }
  .buttons {
    margin-bottom: 25px;
    margin-right: 45px;;
  }
  .subscribe {
    margin-bottom: 16px;
  }  
}
