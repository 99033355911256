@import '../../App.scss';

.enterEmailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.text {
  margin: 206px 66px;
  text-align: center;
}
.headline {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 21px;
}
.description {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 32px;
}

@media screen and (max-width: $min-tablet-width) {
  .enterEmailContainer {
    gap: 13px;

  }
  .headline {
    font-size: 24px;
  }
  .description {
    font-size: 18px;
  }
  .text {
    margin: 41px 33px;
  }  
}